.priceBox {

    span.link-subtitle {
        transition: all 0.5s ease;        
        font-weight: bold;
    }
    .down {
        color:#ff1c5c;
    }

    .up {
        color:#a3ff12;
    }

}