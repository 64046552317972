//- Alert
//- ----------------------------------------------

.alert {
	padding-top: $alert-padding-y;
	padding-bottom: $alert-padding-y;
	padding-right: $alert-padding-x;
	padding-left: $alert-icon-width + $alert-padding-x;
	margin-bottom: $alert-margin-bottom;
	border: $alert-border-width solid transparent;
	@include border-radius($alert-border-radius);
	font-weight: 700;

	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: $alert-icon-width;
	}

	&::after {
		display: block;
		position: absolute;
		left: calc($alert-icon-width / 2);
		top: 50%;
		transform: translateY(-50%);
		font-family: "Font Awesome 5 Free";
		font-weight: $font-weight-base;
		content: "\f00c";
		margin-left: -10px;
		width: 20px;
		height: 20px;
		text-align: center;
		font-size: 10px;
		line-height: 17px;
		border: 2px solid transparent;
		border-radius: 3px;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.alert-success {
	@include alert-variant(
		$alert-success-bg,
		$alert-success-border,
		$alert-success-color
	);

	&::before {
		background-color: $alert-success-border;
	}

	&::after {
		border-color: $alert-success-color;
	}
}

.alert-info {
	@include alert-variant($alert-info-bg, $alert-info-border, $alert-info-color);

	&::before {
		background-color: $alert-info-border;
	}

	&::after {
		border-color: $alert-info-color;
		content: "\f129";
	}
}

.alert-warning {
	@include alert-variant(
		$alert-warning-bg,
		$alert-warning-border,
		$alert-warning-color
	);

	&::before {
		background-color: $alert-warning-border;
	}

	&::after {
		border-color: $alert-warning-color;
		content: "\f12a";
	}
}

.alert-danger {
	@include alert-variant(
		$alert-danger-bg,
		$alert-danger-border,
		$alert-danger-color
	);

	&::before {
		background-color: $alert-danger-border;
	}

	&::after {
		border-color: $alert-danger-color;
		content: "\f00d";
	}
}
