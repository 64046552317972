/*
File Name: 		custom.css
Description:  You can add your custom CSS here and it will overwrite template styles
*/

.header-logo--img > a > img {

    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    -ms-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
                
   &:hover{
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.spinner-grow-sm {
    margin-left:0.2rem;
}

.table-responsive {
    display: block;
    width: 100%;
}


@import "slick-carousel/slick/slick.css";
/*@import "slick-carousel/slick/slick-theme.css";*/


.slick-prev:before,
.slick-next:before { 
    line-height: 114px;
}


.standings-table .match-team-logo:before {
    border-width: 0px;
}

.team-3:not(.match-player--small) .match-player__avatar:before {
    background-color: #ff1c5c;
}


.table-insite > {
    font-size: 0.9rem;


    thead > {
        text-transform: initial;

        tr {
            
            th {
                font-size: 0.925rem;
                padding: 0.5rem 1rem;
                background-color: #13151e;
            }
        }
    }
    tbody > {
        tr {
            td {
                font-size: 0.825rem;
                background-color: #151720;                                         
            }
        }
        tr:nth-child(odd) {
            td {
                font-size: 0.875rem;
                background-color: #1d202c;
            }
        }
    }
}


button.btn-outline-warning {
    color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    border-width:1px;
    border-style:solid;
}

.font-weight-bold{

  font-weight: bold;
}

button.btn-outline-success {
  color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  border-width:1px;
  border-style:solid;
}

.header-account__avatar img {
  border:1px solid #a3ff12;
}

table.table-order  {

    tbody {  
        .input-group {
            padding:0.2rem;
        }

        select {
            color:white;
            padding:2px 12px;
            background-color:#13151e;
            border-color:#13151e;
            width:95%;
            margin:10px;
        }

        label {
            background-color:#13151e;
            padding:6px;
            .full {
                display: block;
            }
        }
        input {
            font-size:13px;
            padding:2px;
            line-height: 16px;
            height :32px;
            border-bottom-color:#13151e;

            & + span {
                font-size:13px;
                font-weight: bold;
            }
            &:focus + span{

                border-color: #88df00;

                background-color:#88df00;
            }
            
        }

        

        .input-group-text {
            color:white;
            height :32px;
            background-color: #13151e;
            border: 1px solid #151720;
        }
    }
}

.text-success {
    color: #a3ff12 !important;
}



.blink {
    animation: blink-animation 1s steps(5, start) 4;  
    -webkit-animation: blink-animation 1s steps(5, start) 4;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }


  /* Custom styling for React Select */
.react-select-container {
    width: 100%;
  }
  
  .react-select__control {
    background-color: #13151e !important;
    border: none;
    border-color:#000 !important;
    color: #fff;
  }
  
  .react-select__control:hover {
    
  }
  
  .react-select__control--is-focused {
    box-shadow: none;
  }
  
  .react-select__indicator-separator {
    background-color: #666;
  }
  
  .react-select__indicator {
    color: #fff;
  }
  
  .react-select__menu {
    background-color: #13151e;
    color: #fff;
    border: none;
  }
  
  .react-select__menu-list {
    background-color: #13151e;
    padding: 0;
  }
  
  .react-select__option {
    padding: 4px 8px;
    background-color: #13151e;
    cursor: pointer;
  }
  
  .react-select__option:hover {
    background-color: #555;
  }
  
  .react-select__option--is-selected {
    background-color: #777;
  }
  
  .react-select__option--is-focused {
    background-color: #555;
  }
  
  .react-select__single-value {
    color: #fff !important;
  }
  
  div.modal-body input {
    font-size: 13px;
    padding: 2px;
    line-height: 16px;
    height: 32px;
    border-bottom-color: #13151e;
}

  div.modal-body input + span {
    font-size: 13px;
    font-weight: bold;
    color: white;
    height: 32px;
    background-color: #13151e;
    border: 1px solid #151720;
}

div.modal-body  input:focus + span {
  border-color: #88df00;
  background-color: #88df00;
}

input[type=range] {
  accent-color: #a3ff12;
  color: #a3ff12;
  appearance: auto;
}


  .btn-short {
    .btn-sm {
      padding:0.6875rem 0.25rem;
    }
  }

  .sb-avatar {
    margin:0 10px;
    
  }
  .sb-avatar:hover {
    
  }


  body:after{
    content: "beta";
    position: fixed;
    width: 200px;
    height: 25px;
    background: #a3ff12;
    text-shadow: 1px 1px 0px black;
    top: 47px;
    left: -40px;
    text-align: center;
    font-size: 16px;
    font-family: sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    line-height: 27px;
    transform:rotate(-45deg);
  }


  .center-logo {
    justify-content: center;
    padding: 0;
    flex: 1;
    display: flex !important;
    align-content: center;
    flex-wrap: inherit;
    padding:0
  }




  .btn-primary {
    --bs-btn-color: #fff; /* White text */
    --bs-btn-bg: #444; /* Dark gray background */
    --bs-btn-border-color: #444; /* Slightly darker border */
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #333; /* Darker gray on hover */
    --bs-btn-hover-border-color: #444; /* Border color on hover */
    --bs-btn-focus-shadow-rgb: 51, 51, 51; /* Dark gray shadow */
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #222; /* Darkest gray */
    --bs-btn-active-border-color: #333; /* Border color on active */
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #777; /* Light gray text for disabled */
    --bs-btn-disabled-bg: #555; /* Lighter gray background for disabled */
    --bs-btn-disabled-border-color: #555; /* Match background for disabled */

    /*color: #a3ff12;
    border-width: 1px;
    border: 1px solid #a3ff12;*/
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  /*color: #a3ff12;
  border-width: 1px;
  border: 1px solid #a3ff12;
  */
}

.sb-avatar__image {
  border:4px solid black;
}