
.pnl-frame {
    background: rgb(11, 11, 11);
    padding: 11px !important;
    border-radius: 2px;
    border: 3px solid #111111;
    box-shadow: 1px 0px 12px #111;
}


.lbt02 > span  {

    font-weight: 900;
    font-size:0.7rem;
    text-shadow: 1px 1px 1px gray
}

.cur_pointer {
    cursor:pointer;
    display: inline-block;
}

.stripe-background {
    display: flex;
    flex-direction: column;
    height: 64px;
    width:300px;
    position:absolute;
    top:0;
    left:0;
    z-index: -1;
}

.stripe {
    flex: 1;
    height: 10%; /* Adjust height as needed */
}
